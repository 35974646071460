import {
  object as yupObject,
  string as yupString,
} from 'yup'

const RECAPTCHA_SITE_KEY = '6Lcg_nsUAAAAAKndIgLUB9tXroXMeJDe7d0Y6gwx'
const SUBMIT_URL = '/.netlify/functions/form-handler'
const FORM_NAME = 'enquiry-form'

export const schema = yupObject().shape({
  submitter: yupObject()
    .shape({
      name: yupString()
        .min(1, 'Too Short')
        .max(255, 'Too Long')
        .required('Required'),
      email: yupString()
        .email('Invalid email')
        .required('Required'),
      tel: yupString()
        .min(6, 'Too Short')
        .max(20, 'Too Long'),
      town: yupString()
        .min(1, 'Too Short')
        .max(100, 'Too Long')
        .required('Required'),
    })
    .required(),
  enquiryType: yupString(),
  message: yupString()
    .min(2, 'Too Short')
    .max(2000, 'Too Long')
    .required('Required'),
})

export const initialValues = {
  submitter: { name: '', tel: '', email: '', town: '' },
  message: '',
}

const getRecaptchaToken = () => {
  return new Promise((resolve, reject) => {
    if (!window || !window.grecaptcha) return reject('Error loading captcha')
    window.grecaptcha
      .execute(RECAPTCHA_SITE_KEY, { action: 'submit' })
      .then(resolve) // resolve token to native promise
  })
}

export const handleSubmit = async formData => {
  const recaptchaToken = await getRecaptchaToken().catch(e => {
    console.log('ReCaptcha Error', e.message)
    throw Error('Verification error')
  })
  const res = await fetch(SUBMIT_URL, {
    method: 'POST',
    body: JSON.stringify({
      formName: FORM_NAME,
      formData,
      recaptchaToken,
    }),
    headers: new Headers({
      'Content-Type': 'application/json',
    }),
  })

  // check for errors
  try {
    const resJSON = await res.json()
    if (resJSON.success !== true) throw Error(resJSON.message)
  } catch (err) {
    console.log(err.message)
    throw Error('Network error')
  }

  if (window && window.dataLayer)
    window.dataLayer.push({
      event: 'enquiry-form-submit',
    })
}
