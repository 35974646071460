import React from 'react'
import styled from '@emotion/styled'
import { container, breakpoints, colors } from '../styles/theme'

import PageBody from '../components/shared/page-body'
import ContactForm from '../components/contact-form'
import Layout from '../components/layout'
import Seo from '../components/SEO'
import SocialIcons from '../components/social-icons'

const ContactPage = () => (
  <Layout>
    <Seo title="Contact Fox Tailoring" pathname="/contact/" />
    <PageBody>
      <TitleContainer>
        <h1 className="heading--h1">Contact Fox Tailoring</h1>
      </TitleContainer>
      <Container>
        <div>
          <AppointmentContainer>
            <h2 className="heading--h4">Book an appointment</h2>
            <BooksyWidget />
          </AppointmentContainer>
          <ContactForm />
        </div>
        <OfflineContactContainer>
          <SocialIcons dark />
          <ShowroomsHeading className="heading--h2">
            Fox Tailoring Showrooms
          </ShowroomsHeading>
          <div>
            <strong>by appointment only</strong>
          </div>
          <ShowroomBlock
            heading="Bournemouth"
            address={
              <p>
                Compton Acres
                <br />
                164 Canford Cliffs Road
                <br />
                Poole
                <br />
                Dorset BH13 7ES
              </p>
            }
            tel="01202 289090"
          />
          <ShowroomBlock
            heading="Essex"
            address={
              <p>
                Apex House
                <br />
                Bryant Avenue
                <br />
                Romford RM3 0AP
              </p>
            }
            tel="01708 438761"
          />
        </OfflineContactContainer>
      </Container>
    </PageBody>
  </Layout>
)

const ShowroomsHeading = styled('h2')`
  margin: 0 0 4px;
`

const ShowroomBlock = ({ heading, address, tel }) => (
  <ShowroomContainer>
    <h2 className="heading heading--h4">{heading}</h2>
    <Address>{address}</Address>
    <Tel>
      Tel: <span>{tel}</span>
    </Tel>
  </ShowroomContainer>
)

const ShowroomContainer = styled('section')`
  margin-top: 32px;
  text-align: center;
`

const Address = styled.div`
  p {
    margin: 8px 0 0;
  }
`

const Tel = styled.div`
  margin-top: 8px;
  font-size: 2.2rem;
  font-weight: 500;
`

const TitleContainer = styled.div`
  ${container}
`

const Container = styled.div`
  ${container}
  @media (min-width: ${breakpoints.medium}px) {
    display: flex;
    justify-content: center;
    flex-flow: row wrap;
  }
  > * {
    margin-bottom: 40px;
  }
`

const AppointmentContainer = styled.div`
  background: ${colors.white};
  border: 1px solid #fff;
  box-shadow: 0 2px 16px ${colors.midGrey}55;
  margin-bottom: 30px;
  padding: 10px 48px 8px;

  display: flex;
  flex-flow: row wrap;
  gap: 20px;
  justify-content: center;
  align-items: center;

  position: relative;
  overflow: hidden;

  h2 {
    margin-block: 10px;
  }
`

const OfflineContactContainer = styled.div`
  ${container}
  flex: 1;
  padding: 16px;
  text-align: center;
  min-width: 240px;

  @media (min-width: ${breakpoints.medium}px) {
    margin-left: 16px;
  }
`

export default ContactPage

const BooksyWidget = React.memo(() => {
  const booksyWidgetRef = React.useRef()
  React.useEffect(() => {
    if (!booksyWidgetRef.current) return
    const script = document.createElement('script')
    script.type = 'text/javascript'
    script.src =
      'https://booksy.com/widget/code.js?id=101503&country=gb&lang=en-GB'
    script.async = true
    booksyWidgetRef.current.appendChild(script)

    // to prevent the following error after unmount and mount again:
    // Uncaught Error: [Booksy][widget][error] cannot locate current script
    return () => {
      if (!window.booksy) return
      delete window.booksy
    }
  }, [])

  return <div ref={booksyWidgetRef}></div>
})
