import React from 'react'
import styled from '@emotion/styled'
import { Field, ErrorMessage } from 'formik'
import { colors, radius } from '../../styles/theme'

export default React.memo(
  ({ name, label, type = null, children, className, ...passthrough }) => (
    <Container className={className}>
      <LabelErrorContainer>
        {label && (
          <Label htmlFor={name} required={passthrough.required}>
            {label}
          </Label>
        )}
        <ErrorWrapper>
          <ErrorMessage name={name} />
        </ErrorWrapper>
      </LabelErrorContainer>
      {!children ? (
        <Field id={name} name={name} type={type} {...passthrough} />
      ) : (
        <Field id={name} name={name} type={type} {...passthrough}>
          {children}
        </Field>
      )}
    </Container>
  )
)

const Container = styled.div`
  min-width: 240px;
  text-align: left;

  input,
  select,
  textarea {
    width: 100%;
    font-size: 1.9rem;
    border-radius: ${radius.default}px;
    border: 2px solid ${colors.primary};
    padding-left: 12px;
    padding-right: 12px;

    transition: all 0.3s ease;

    &:focus {
      outline: none;
      border-color: ${colors.accent};
      box-shadow: 0 0 16px 0px ${colors.accent}55;
    }
  }

  input,
  select {
    height: 48px;
  }

  select {
    -webkit-appearance: none;
    background-size: 22px 22px;
    background-position: calc(100% - 12px) 50%;
  }

  textarea {
    padding-top: 12px;
    padding-bottom: 12px;
  }
`

const LabelErrorContainer = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: baseline;
  margin: 0 8px 2px;
`

const Label = styled('label')`
  font-weight: 500;

  ${p =>
    p.required &&
    `
    &::after {
      content: "*";
      margin-left: 2px;
      color: ${colors.primary};
    }
  `}
`

const ErrorWrapper = styled.span`
  font-size: 1.6rem;
  margin-left: 8px;
  font-weight: 500;
  color: ${colors.accent};
`
