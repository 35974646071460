import React, { useState } from 'react'
import styled from '@emotion/styled'
import { keyframes } from '@emotion/react'
import { Formik } from 'formik'
import { motion, AnimatePresence } from 'framer-motion'

import { colors } from '../../styles/theme'
import SuccessMessage from './success-message'
import SubmissionError from './error-message'

const modalVariants = {
  visible: {
    opacity: 1,
    scale: 1,
  },
  hidden: {
    opacity: 0,
    scale: 0,
  },
}

const FormContainer = ({ children, schema, initialValues, handleSubmit }) => {
  const [submitSuccess, setSubmitSuccess] = useState(false)
  const handleSubmitSuccess = () => setSubmitSuccess(true)
  const handleReset = () => setSubmitSuccess(false)

  return (
    <Formik
      initialValues={initialValues}
      validationSchema={schema}
      validateOnChange={false}
      onSubmit={async (formData, { setSubmitting, resetForm, setStatus }) => {
        try {
          await handleSubmit(formData)
          setSubmitting(false)
          handleSubmitSuccess()
          resetForm()
        } catch (submissionError) {
          setSubmitting(false)
          setStatus({ submissionError })
        }
      }}
    >
      {({ errors, dirty, isSubmitting, status = {} }) => (
        <motion.div
          animate={
            isSubmitting
              ? 'submitting'
              : status.submissionError
              ? 'error'
              : submitSuccess
              ? 'submitSuccess'
              : 'editing'
          }
        >
          <FormPanel>
            <SubmittingStatusBar
              visible={isSubmitting}
              active={isSubmitting}
              error={Object.values(errors).length > 0}
            />
            <AnimatePresence>
              {submitSuccess && (
                <FormMessage
                  animate="visible"
                  exit="hidden"
                  key="submissionSuccess"
                >
                  <SuccessMessage handleReset={handleReset} />
                </FormMessage>
              )}
              {!isSubmitting && status.submissionError && (
                <FormMessage
                  animate="visible"
                  exit="hidden"
                  key="submissionError"
                >
                  <SubmissionError message={status.submissionError.message} />
                </FormMessage>
              )}
            </AnimatePresence>
            <motion.div
              variants={formWrapperVariants}
              style={{ overflow: 'hidden' }}
            >
              {children({ dirty, isSubmitting })}
            </motion.div>
          </FormPanel>
        </motion.div>
      )}
    </Formik>
  )
}

const FormMessage = ({ animate, onAnimationComplete, children }) => (
  <motion.div
    animate={animate}
    onAnimationComplete={onAnimationComplete}
    variants={modalVariants}
    initial="hidden"
  >
    {children}
  </motion.div>
)

const formWrapperVariants = {
  submitSuccess: {
    height: 0,
    opacity: 0,
    delay: 100,
  },
  submitting: {
    opacity: 0.3,
  },
  editing: {
    height: 'auto',
    opacity: 1,
    delay: 100,
  },
}

const FormPanel = styled.div`
  background: ${colors.white};
  border: 1px solid #fff;
  box-shadow: 0 2px 16px ${colors.midGrey}55;
  padding: 30px 48px;

  position: relative;
  overflow: hidden;
`

const progressAnimation = keyframes`100% {
  background-position: -200px;
}`

const SubmittingStatusBar = styled('div')`
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  height: 16px;
  transition: transform 0.3s ease;
  transform: translateY(${(p) => (p.visible ? '0' : '-100%')});
  background: ${(p) => (p.error ? 'red' : colors.primary)};
  z-index: 99;

  background-size: 100px 100%;
  background-image: linear-gradient(
    20deg,
    transparent 33%,
    rgba(0, 0, 0, 0.15) 33%,
    rgba(0, 0, 0, 0.15) 66%,
    transparent 66%
  );

  animation: ${(p) => (p.active ? progressAnimation : 'none')} 1.5s linear
    infinite;
`

export default FormContainer
