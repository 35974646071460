import React from 'react'

const SubmissionError = ({ message }) => (
  <div style={{ marginBottom: '48px' }}>
    {message && <div className="heading heading--h4">{message}</div>}
    <h3 className="heading heading--h3">Sorry, something went wrong</h3>
    <p>Please try again and if the problem persists phone our office.</p>
  </div>
)

export default SubmissionError
