import React from 'react'
import styled from '@emotion/styled'
import { Form } from 'formik'
import FormContainer from './form/form-container'
import Field from './form/field'
import { breakpoints, button, colors } from '../styles/theme'
import {
  schema,
  initialValues,
  handleSubmit,
} from '../components/form/enquiry-form-handler'

const ContactForm = () => (
  <FormContainer
    schema={schema}
    initialValues={initialValues}
    handleSubmit={handleSubmit}
  >
    {({ isSubmitting, dirty }) => (
      <Form name="contact-form">
        <h2
          className="heading--h4"
          style={{ textAlign: 'center', marginTop: 0 }}
        >
          Submit an enquiry
        </h2>
        <FieldContainer>
          <Field
            name="submitter.name"
            label="Your Name"
            autoComplete="name"
            required
          />
          <Field
            name="submitter.tel"
            label="Telephone"
            type="tel"
            autoComplete="tel"
          />
          <Field
            name="submitter.email"
            label="Email"
            type="email"
            autoComplete="email"
            required
          />
          <Field
            name="submitter.town"
            label="Town"
            autoComplete="address-level2"
            required
          />
          <Field
            className="field--message"
            name="message"
            label="Message"
            component="textarea"
            rows="10"
            placeholder="How can we help?"
            required
          />
        </FieldContainer>
        <ButtonGroup>
          <SubmitButton type="submit" disabled={isSubmitting}>
            Submit
          </SubmitButton>
          <ResetButton type="reset" disabled={!dirty || isSubmitting}>
            Reset
          </ResetButton>
        </ButtonGroup>

        <RecaptchaNotice>
          <p>
            This site is protected by reCAPTCHA and the Google{' '}
            <a href="https://policies.google.com/privacy">Privacy Policy</a> and{' '}
            <a href="https://policies.google.com/terms">Terms of Service</a>{' '}
            apply.
          </p>
        </RecaptchaNotice>
      </Form>
    )}
  </FormContainer>
)

const ButtonGroup = styled.div`
  margin: 34px auto 0;
  max-width: 240px;
  display: flex;
  justify-content: center;
  > :not(:last-child) {
    margin-right: 8px;
  }
`

const SubmitButton = styled.button`
  ${button.default}
  background: ${colors.accent};
  border-style: solid;
  border-color: ${colors.accent};
  color: #fff;
`

const ResetButton = styled.button`
  ${button.default}
  ${button.outline}
  border-color: ${colors.lightGrey};
  color: ${colors.midGrey};
`

const FieldContainer = styled.div`
  display: grid;
  justify-content: center;
  grid-gap: 16px;
  grid-template-columns: 1fr;
  @media (min-width: ${breakpoints.medium}px) {
    grid-template-columns: min-max(auto, 320px) 1fr;
    .field--message {
      grid-column: span 2;
    }
  }
`

const RecaptchaNotice = styled.div`
  p {
    max-width: 340px;
    text-align: center;
    margin: 24px auto 0;
    font-size: 0.8em;
    color: ${colors.midGrey};
    font-weight: 500;
  }
  a {
    text-decoration: none;
  }
`

export default ContactForm
