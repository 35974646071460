import React from 'react'

const SuccessMessage = ({ handleReset }) => (
  <div>
    <h3 className="heading heading--h2">Message Sent</h3>
    <div className="heading heading--h1">Thank you for your enquiry.</div>
    <p>We aim to respond with 24 hours.</p>
    <div style={{ marginTop: '24px' }}>
      <button onClick={handleReset}>Send another message</button>
    </div>
  </div>
)

export default SuccessMessage
